import React from 'react';
import { Link } from 'react-router-dom';

const ServiceAreaTwo = () => {
  return (
    <>
      {/*=================== service area start ===================*/}
      <div className="service-area bg-gray bg-relative  pd-bottom-90 ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-title text-center">
                <h6 className="sub-title">ADVANCED SERVICES</h6>
                <h2 className="title">
                Why Choose <span>Cloud Classes</span> As Your Online Classes App
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner-2 text-center">
                <div className="thumb">
                  <img src="assets/img/service/host.jpg" alt="img" />
                </div>
                <div className="details">
                  {/* <div className="icon mb-3">
                    <img src="assets/img/service/8.png" alt="img" />
                  </div> */}
                  <h5>
                    <Link to="/service-details">Host Live & Recorded Courses by Category</Link>
                  </h5>
                  {/* <p>SaaS stands for Software as a Service. It is a software</p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner-2 text-center">
                <div className="thumb">
                  <img src="assets/img/service/provide.jpg" alt="img" />
                </div>
                <div className="details">
                  {/* <div className="icon mb-3">
                    <img src="assets/img/service/9.png" alt="img" />
                  </div> */}
                  <h5>
                    <Link to="/service-details">Provide Free & Demo Classes</Link>
                  </h5>
                  {/* <p>SaaS stands for Software as a Service. It is a software</p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner-2 text-center">
                <div className="thumb">
                  <img src="assets/img/service/doubt.jpg" alt="img" />
                </div>
                <div className="details">
                  {/* <div className="icon mb-3">
                    <img src="assets/img/service/10.png" alt="img" />
                  </div> */}
                  <h5>
                    <Link to="/service-details">Doubt Posting & Discussion Section</Link>
                  </h5>
                  {/* <p>SaaS stands for Software as a Service. It is a software</p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner-2 text-center">
                <div className="thumb">
                  <img src="assets/img/service/branding.jpg" alt="img" />
                </div>
                <div className="details">
                  {/* <div className="icon mb-3">
                    <img src="assets/img/service/10.png" alt="img" />
                  </div> */}
                  <h5>
                    <Link to="/service-details">Personalized Branding of the App</Link>
                  </h5>
                  {/* <p>SaaS stands for Software as a Service. It is a software</p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner-2 text-center">
                <div className="thumb">
                  <img src="assets/img/service/courses.jpg" alt="img" />
                </div>
                <div className="details">
                  {/* <div className="icon mb-3">
                    <img src="assets/img/service/10.png" alt="img" />
                  </div> */}
                  <h5>
                    <Link to="/service-details">Easily Sell Your Courses Online</Link>
                  </h5>
                  {/* <p>SaaS stands for Software as a Service. It is a software</p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner-2 text-center">
                <div className="thumb">
                  <img src="assets/img/service/mock.jpg" alt="img" />
                </div>
                <div className="details">
                  {/* <div className="icon mb-3">
                    <img src="assets/img/service/10.png" alt="img" />
                  </div> */}
                  <h5>
                    <Link to="/service-details">Conduct Mock Tests & Sell Test Series.</Link>
                  </h5>
                
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner-2 text-center">
                <div className="thumb">
                  <img src="assets/img/service/quick.jpg" alt="img" />
                </div>
                <div className="details">
                  {/* <div className="icon mb-3">
                    <img src="assets/img/service/10.png" alt="img" />
                  </div> */}
                  <h5>
                    <Link to="/service-details">Quick & Easy Student Teacher Communication</Link>
                  </h5>
                
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner-2 text-center">
                <div className="thumb">
                  <img src="assets/img/service/portal.jpg" alt="img" />
                </div>
                <div className="details">
                  {/* <div className="icon mb-3">
                    <img src="assets/img/service/10.png" alt="img" />
                  </div> */}
                  <h5>
                    <Link to="/service-details">Comprehensive Portals for Teachers, Students & Admin</Link>
                  </h5>
                
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner-2 text-center">
                <div className="thumb">
                  <img src="assets/img/service/live.jpg" alt="img" />
                </div>
                <div className="details">
                  {/* <div className="icon mb-3">
                    <img src="assets/img/service/10.png" alt="img" />
                  </div> */}
                  <h5>
                    <Link to="/service-details">Live Online Classes</Link>
                  </h5>
                
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner-2 text-center">
                <div className="thumb">
                  <img src="assets/img/service/test.jpg" alt="img" />
                </div>
                <div className="details">
                  {/* <div className="icon mb-3">
                    <img src="assets/img/service/10.png" alt="img" />
                  </div> */}
                  <h5>
                    <Link to="/service-details">Online Testing</Link>
                  </h5>
                
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner-2 text-center">
                <div className="thumb">
                  <img src="assets/img/service/newsletter.jpg" alt="img" />
                </div>
                <div className="details">
                  {/* <div className="icon mb-3">
                    <img src="assets/img/service/10.png" alt="img" />
                  </div> */}
                  <h5>
                    <Link to="/service-details">Newsletters and Communications</Link>
                  </h5>
                
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner-2 text-center">
                <div className="thumb">
                  <img src="assets/img/service/invoice.jpg" alt="img" />
                </div>
                <div className="details">
                  {/* <div className="icon mb-3">
                    <img src="assets/img/service/10.png" alt="img" />
                  </div> */}
                  <h5>
                    <Link to="/service-details">Invoicing and Online Payments</Link>
                  </h5>
                
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner-2 text-center">
                <div className="thumb">
                  <img src="assets/img/service/crm.jpg" alt="img" />
                </div>
                <div className="details">
                  {/* <div className="icon mb-3">
                    <img src="assets/img/service/10.png" alt="img" />
                  </div> */}
                  <h5>
                    <Link to="/service-details">CRM</Link>
                  </h5>
                
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner-2 text-center">
                <div className="thumb">
                  <img src="assets/img/service/attendance.jpg" alt="img" />
                </div>
                <div className="details">
                  {/* <div className="icon mb-3">
                    <img src="assets/img/service/10.png" alt="img" />
                  </div> */}
                  <h5>
                    <Link to="/service-details">Attendance</Link>
                  </h5>
                
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner-2 text-center">
                <div className="thumb">
                  <img src="assets/img/service/scheduling.jpg" alt="img" />
                </div>
                <div className="details">
                  {/* <div className="icon mb-3">
                    <img src="assets/img/service/10.png" alt="img" />
                  </div> */}
                  <h5>
                    <Link to="/service-details">Scheduling</Link>
                  </h5>
                
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner-2 text-center">
                <div className="thumb">
                  <img src="assets/img/service/courses.jpg" alt="img" />
                </div>
                <div className="details">
                  {/* <div className="icon mb-3">
                    <img src="assets/img/service/10.png" alt="img" />
                  </div> */}
                  <h5>
                    <Link to="/service-details">Market Your Courses on Home Page</Link>
                  </h5>
                
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner-2 text-center">
                <div className="thumb">
                  <img src="assets/img/service/whitelabled.jpg" alt="img" />
                </div>
                <div className="details">
                  {/* <div className="icon mb-3">
                    <img src="assets/img/service/10.png" alt="img" />
                  </div> */}
                  <h5>
                    <Link to="/service-details">Whitelabelled solution</Link>
                  </h5>
                
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner-2 text-center">
                <div className="thumb">
                  <img src="assets/img/service/whiteboard.jpg" alt="img" />
                </div>
                <div className="details">
                  {/* <div className="icon mb-3">
                    <img src="assets/img/service/10.png" alt="img" />
                  </div> */}
                  <h5>
                    <Link to="/service-details">Whiteboard</Link>
                  </h5>
                
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </>
  );
};

export default ServiceAreaTwo;
