import React from 'react';
import { FaCheckCircle , FaReact, FaAngular, FaVuejs, FaHtml5, FaCss3, FaJs, FaLaravel, FaPython, FaNodeJs, FaPhp, FaSwift, FaApple, FaAndroid, FaPhone, FaMobile, FaDatabase} from 'react-icons/fa';


const CareerAreaOne = () => {
  return (
    <>
      {/* career area start */}
      <div className="career-section career-section_1 pd-top-120 ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-title text-center">
                <h6 className="sub-title">Innovate Together</h6>
                <h2 className="title">
                <span>Tech</span> that Clicks with You
                </h2>
              </div>
            </div>
          </div>
          <div className="career-wrap-area">
            <nav>
              <div
                className="nav nav-tabs career-nav-tab"
                id="nav-tab"
                role="tablist"
              >
                <button
                  className="nav-link active"
                  id="nav-mission-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-mission"
                  type="button"
                  role="tab"
                  aria-controls="nav-mission"
                  aria-selected="true"
                >
                 Frontend Development
                </button>
                <button
                  className="nav-link"
                  id="nav-vision-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-vision"
                  type="button"
                  role="tab"
                  aria-controls="nav-vision"
                  aria-selected="false"
                >
                  Backend Development
                </button>
                <button
                  className="nav-link"
                  id="nav-career-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-career"
                  type="button"
                  role="tab"
                  aria-controls="nav-career"
                  aria-selected="false"
                >
                  Mobile App Development
                </button>
                <button
                  className="nav-link"
                  id="nav-solution-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-solution"
                  type="button"
                  role="tab"
                  aria-controls="nav-solution"
                  aria-selected="false"
                >
                  Database Development
                </button>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              <div
                className="tab-pane fade show active"
                id="nav-mission"
                role="tabpanel"
                aria-labelledby="nav-mission-tab"
              >
                <div className="career-wrap">
                  <div className="row">
                    <div className="col-lg-5">
                      <img src="assets/img/about/frontend.jpg" alt="img" />
                    </div>
                    <div className="col-lg-7">
                      <h3 className="mb-3">Frontend Development</h3>
                      <p className="mb-3">
                      Front-end development at our company involves using modern technologies like React, Vue, and Angular, combined with HTML/CSS and JSS. We create responsive, interactive, and visually stunning user interfaces that deliver a seamless experience across all devices and platforms.</p>
                      <div className="row">
                        <div className="col-md-6">
                          <ul className="single-list-inner style-check style-heading style-check mb-3">
                            <li>
                              <FaReact /> React JS
                            </li>
                            <li>
                              <FaAngular /> Angular JS
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6">
                          <ul className="single-list-inner style-check style-heading style-check mb-3">
                            <li>
                              <FaVuejs /> Vue JS
                            </li>
                            <li>
                              <FaHtml5 /> HTML/CSS/JS
                            </li>
                          </ul>
                        </div>

                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="nav-vision"
                role="tabpanel"
                aria-labelledby="nav-vision-tab"
              >
                <div className="career-wrap">
                  <div className="row">
                    <div className="col-lg-5">
                      <img src="assets/img/about/backend.jpg" alt="img" />
                    </div>
                    <div className="col-lg-7">
                      <h3 className="mb-3">Backend Development</h3>
                      <p className="mb-3">
                      Backend development is the backbone of any application, handling server-side logic, databases, and APIs. It ensures data processing, storage, and security while enabling seamless communication between the frontend and server. Our solutions prioritize performance, scalability, and reliability.
                      </p>
                      <div className="row">
                        <div className="col-md-6">
                          <ul className="single-list-inner style-check style-heading style-check mb-3">
                            <li>
                              <FaLaravel /> Laravel
                            </li>
                            <li>
                              <FaPython /> Python
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6">
                          <ul className="single-list-inner style-check style-heading style-check mb-3">
                            <li>
                              <FaNodeJs />Node JS
                            </li>
                            <li>
                              <FaPhp /> PHP
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="nav-career"
                role="tabpanel"
                aria-labelledby="nav-career-tab"
              >
                <div className="career-wrap">
                  <div className="row">
                    <div className="col-lg-5">
                      <img src="assets/img/about/mobileapps.jpg" alt="img" />
                    </div>
                    <div className="col-lg-7">
                      <h3 className="mb-3">Android & IOS Development</h3>
                      <p className="mb-3">
                      Android and iOS development focuses on creating intuitive and high-performance mobile applications. We design apps that are optimized for both platforms, ensuring a seamless user experience, robust functionality, and smooth integration with device features, catering to diverse user needs.
                      </p>
                      <div className="row">
                        <div className="col-md-6">
                          <ul className="single-list-inner style-check style-heading style-check mb-3">
                            <li>
                              <FaAndroid /> Kotlin/Java
                            </li>
                            <li>
                              <FaReact /> React Native
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6">
                          <ul className="single-list-inner style-check style-heading style-check mb-3">
                            <li>
                              <FaSwift /> Swift
                            </li>
                            <li>
                              <FaMobile/> Flutter
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="nav-solution"
                role="tabpanel"
                aria-labelledby="nav-solution-tab"
              >
                <div className="career-wrap">
                  <div className="row">
                    <div className="col-lg-5">
                      <img src="assets/img/about/database.jpg" alt="img" />
                    </div>
                    <div className="col-lg-7">
                      <h3 className="mb-3">Database Development</h3>
                      <p className="mb-3">
                      Database development involves designing, implementing, and managing databases to store and retrieve data efficiently. It ensures data integrity, security, and scalability while optimizing performance. We create robust database solutions that support seamless data access and management for diverse applications and business needs.
                      </p>
                      <div className="row">
                        <div className="col-md-6">
                          <ul className="single-list-inner style-check style-heading style-check mb-3">
                            <li>
                              <FaDatabase /> MySQL
                            </li>
                            <li>
                              <FaDatabase /> PostGre SQL
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6">
                          <ul className="single-list-inner style-check style-heading style-check mb-3">
                            <li>
                              <FaDatabase /> REDIS
                            </li>
                            <li>
                              <FaDatabase /> MangoDB
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* career area start */}
    </>
  );
};

export default CareerAreaOne;
