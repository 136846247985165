import React from 'react';
import { FaAngleLeft, FaAngleRight, FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

const CaseStudyAreaThree = () => {
  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return <FaAngleRight className={className} onClick={onClick} />;
  }
  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return <FaAngleLeft className={className} onClick={onClick} />;
  }
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      {/*================== case-study area start ==================*/}
      <div
        className="gallery-area bg-relative pd-top-120 pd-bottom-90"
        style={{ backgroundImage: 'url("./assets/img/bg/15.png")' }}
      >
        <div className="container ">
          <div className="row">
            <div className="col-lg-5">
              <div className="section-title style-white">
                <h6 className="bg-none color-base">Best gallery</h6>
                <h2 className="title">So, How it looks?</h2>
              </div>
            </div>
          </div>
          <div className="team-slider owl-carousel slider-control-round white slider-control-right-top">
            <Slider {...settings}>
              <div className="item">
                <div className="single-project-inner style-2">
                  <img src="assets/img/gallery/ss1.png" alt="img" />
                  {/* <div className="details">
                    <span>
                      <FaPlus />
                    </span>
                    <Link className="read-more-text" to="#">
                      Vegas It Park
                    </Link>
                    <p>
                      Lorem is Ipsum is simply is design designer isthe text
                      Lorem Ipsum{' '}
                    </p>
                  </div> */}
                </div>
              </div>
              <div className="item">
                <div className="single-project-inner style-2">
                  <img src="assets/img/gallery/ss3.png" alt="img" />
                  {/* <div className="details">
                    <span>
                      <FaPlus />
                    </span>
                    <Link className="read-more-text" to="#">
                      Vegas It Park
                    </Link>
                    <p>
                      Lorem is Ipsum is simply is design designer isthe text
                      Lorem Ipsum{' '}
                    </p>
                  </div> */}
                </div>
              </div>
              <div className="item">
                <div className="single-project-inner  style-2">
                  <img src="assets/img/gallery/ss2.png" alt="img" />
                  {/* <div className="details">
                    <span>
                      <FaPlus />
                    </span>
                    <Link className="read-more-text" to="#">
                      Vegas It Park
                    </Link>
                    <p>
                      Lorem is Ipsum is simply is design designer isthe text
                      Lorem Ipsum{' '}
                    </p>
                  </div> */}
                </div>
              </div>
              <div className="item">
                <div className="single-project-inner style-2">
                  <img src="assets/img/gallery/ss5.png" alt="img" />
                  {/* <div className="details">
                    <span>
                      <FaPlus />
                    </span>
                    <Link className="read-more-text" to="#">
                      Vegas It Park
                    </Link>
                    <p>
                      Lorem is Ipsum is simply is design designer isthe text
                      Lorem Ipsum{' '}
                    </p>
                  </div> */}
                </div>
              </div>
              <div className="item">
                <div className="single-project-inner style-2">
                  <img src="assets/img/gallery/ss4.png" alt="img" />
                  {/* <div className="details">
                    <span>
                      <FaPlus />
                    </span>
                    <Link className="read-more-text" to="#">
                      Vegas It Park
                    </Link>
                    <p>
                      Lorem is Ipsum is simply is design designer isthe text
                      Lorem Ipsum{' '}
                    </p>
                  </div> */}
                </div>
              </div>
              <div className="item">
                <div className="single-project-inner style-2">
                  <img src="assets/img/gallery/ss6.png" alt="img" />
                  {/* <div className="details">
                    <span>
                      <FaPlus />
                    </span>
                    <Link className="read-more-text" to="#">
                      Vegas It Park
                    </Link>
                    <p>
                      Lorem is Ipsum is simply is design designer isthe text
                      Lorem Ipsum{' '}
                    </p>
                  </div> */}
                </div>
              </div>
              <div className="item">
                <div className="single-project-inner style-2">
                  <img src="assets/img/gallery/ss7.png" alt="img" />
                  {/* <div className="details">
                    <span>
                      <FaPlus />
                    </span>
                    <Link className="read-more-text" to="#">
                      Vegas It Park
                    </Link>
                    <p>
                      Lorem is Ipsum is simply is design designer isthe text
                      Lorem Ipsum{' '}
                    </p>
                  </div> */}
                </div>
              </div>
              <div className="item">
                <div className="single-project-inner style-2">
                  <img src="assets/img/gallery/ss11.png" alt="img" />
                  {/* <div className="details">
                    <span>
                      <FaPlus />
                    </span>
                    <Link className="read-more-text" to="#">
                      Vegas It Park
                    </Link>
                    <p>
                      Lorem is Ipsum is simply is design designer isthe text
                      Lorem Ipsum{' '}
                    </p>
                  </div> */}
                </div>
              </div>
              <div className="item">
                <div className="single-project-inner style-2">
                  <img src="assets/img/gallery/ss8.png" alt="img" />
                  {/* <div className="details">
                    <span>
                      <FaPlus />
                    </span>
                    <Link className="read-more-text" to="#">
                      Vegas It Park
                    </Link>
                    <p>
                      Lorem is Ipsum is simply is design designer isthe text
                      Lorem Ipsum{' '}
                    </p>
                  </div> */}
                </div>
              </div>
              <div className="item">
                <div className="single-project-inner style-2">
                  <img src="assets/img/gallery/ss9.png" alt="img" />
                  {/* <div className="details">
                    <span>
                      <FaPlus />
                    </span>
                    <Link className="read-more-text" to="#">
                      Vegas It Park
                    </Link>
                    <p>
                      Lorem is Ipsum is simply is design designer isthe text
                      Lorem Ipsum{' '}
                    </p>
                  </div> */}
                </div>
              </div>
              <div className="item">
                <div className="single-project-inner style-2">
                  <img src="assets/img/gallery/ss10.png" alt="img" />
                  {/* <div className="details">
                    <span>
                      <FaPlus />
                    </span>
                    <Link className="read-more-text" to="#">
                      Vegas It Park
                    </Link>
                    <p>
                      Lorem is Ipsum is simply is design designer isthe text
                      Lorem Ipsum{' '}
                    </p>
                  </div> */}
                </div>
              </div>
              <div className="item">
                <div className="single-project-inner style-2">
                  <img src="assets/img/gallery/ss13.png" alt="img" />
                  {/* <div className="details">
                    <span>
                      <FaPlus />
                    </span>
                    <Link className="read-more-text" to="#">
                      Vegas It Park
                    </Link>
                    <p>
                      Lorem is Ipsum is simply is design designer isthe text
                      Lorem Ipsum{' '}
                    </p>
                  </div> */}
                </div>
              </div>
              <div className="item">
                <div className="single-project-inner style-2">
                  <img src="assets/img/gallery/ss12.png" alt="img" />
                  {/* <div className="details">
                    <span>
                      <FaPlus />
                    </span>
                    <Link className="read-more-text" to="#">
                      Vegas It Park
                    </Link>
                    <p>
                      Lorem is Ipsum is simply is design designer isthe text
                      Lorem Ipsum{' '}
                    </p>
                  </div> */}
                </div>
              </div>
            
              <div className="item">
                <div className="single-project-inner style-2">
                  <img src="assets/img/gallery/ss14.png" alt="img" />
                  {/* <div className="details">
                    <span>
                      <FaPlus />
                    </span>
                    <Link className="read-more-text" to="#">
                      Vegas It Park
                    </Link>
                    <p>
                      Lorem is Ipsum is simply is design designer isthe text
                      Lorem Ipsum{' '}
                    </p>
                  </div> */}
                </div>
              </div>
              <div className="item">
                <div className="single-project-inner style-2">
                  <img src="assets/img/gallery/ss15.png" alt="img" />
                  {/* <div className="details">
                    <span>
                      <FaPlus />
                    </span>
                    <Link className="read-more-text" to="#">
                      Vegas It Park
                    </Link>
                    <p>
                      Lorem is Ipsum is simply is design designer isthe text
                      Lorem Ipsum{' '}
                    </p>
                  </div> */}
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>

      {/* ================== case-study area end ==================*/}
    </>
  );
};

export default CaseStudyAreaThree;
