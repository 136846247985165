import React from 'react';

const AboutAreaFour = () => {
  return (
    <>
      {/* =============== About Area Three End ===============*/}
      <div className="about-area about-area_4 pd-top-120 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="about-thumb-inner p-xl-5 pt-4 "
                data-aos="fade-right"
                data-aos-delay="200"
                data-aos-duration="1500"
              >
                <img
                  className="animate-main-img"
                  src="assets/img/about/14.png"
                  alt="img"
                />
                <img
                  className="main-img m-md-4"
                  src="assets/img/about/provider.jpg"
                  alt="img"
                />
              </div>
            </div>
            <div
              className="col-lg-6 align-self-center "
              data-aos="fade-left"
              data-aos-delay="250"
              data-aos-duration="1500"
            >
              <div className="section-title mt-5 mt-lg-0">
                <h6 className="sub-title">ABOUT US</h6>
                <h2 className="title">
                Your Complete <span>IT Solutions</span> Provider
                </h2>
                <p className="content mb-4 mb-xl-5">
                
                We are an IT agency specializing in providing services like web development, custom application solutions, digital marketing, search engine optimization (SEO), content marketing, social media management and email marketing. Our web development and custom application development services are designed to create engaging and functional websites and provide you better user experience. Our digital marketing services help you to increase your online visibility and drive organic traffic.
                </p>
                {/* <p className="content mb-4 mb-xl-5">
Our goal is to provide you with tools that streamline your work process and enhance efficiency. We focus on delivering personalized and practical solutions that align with your vision and objectives.

                </p> */}
                <div className="media box-shadow p-3 border-radius-5 mb-3">
                  <div className="media-left me-3">
                    <img src="assets/img/icon/31.svg" alt="img" />
                  </div>
                  <div className="media-body">
                    <div className="single-progressbar">
                      <h6>Product Based</h6>
                      <div className="skill">
                        <div
                          style={{ width: '70%' }}
                          className="skill-bar skill1 wow slideInLeft animated"
                        ></div>
                        <p className="skill-count1">
                          <span className="counter">70</span> %
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="media box-shadow p-3 border-radius-5 mb-3">
                  <div className="media-left me-3">
                    <img src="assets/img/icon/32.svg" alt="img" />
                  </div>
                  <div className="media-body">
                    <div className="single-progressbar">
                      <h6>Service Based</h6>
                      <div className="skill">
                        <div
                          style={{ width: '30%' }}
                          className="skill-bar skill1 wow slideInLeft animated"
                        ></div>
                        <p className="skill-count1">
                          <span className="counter">30</span> %
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =============== About Area Three End ===============*/}
    </>
  );
};

export default AboutAreaFour;
