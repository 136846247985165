import React from "react";
import AboutAreaFour from "../components/AboutAreaFour";
import BannerFour from "../components/BannerFour";
import BlogAreaFour from "../components/BlogAreaFour";
import BrandAreaOne from "../components/BrandAreaOne";
import CareerAreaOne from "../components/CareerAreaOne";
import ContactAreaFour from "../components/ContactAreaFour";
import CounterAreaFour from "../components/CounterAreaFour";
import FaqAreaTwo from "../components/FaqAreaTwo";
import FooterThree from "../components/FooterThree";
import NavbarThree from "../components/NavbarThree";
import ProjectAreaOne from "../components/ProjectAreaOne";
import ServiceAreaFour from "../components/ServiceAreaFour";
import TeamAreaTwo from "../components/TeamAreaTwo";
import TestimonialThree from "../components/TestimonialThree";
import ServiceAreaGroupFour from "../components/ServiceAreaGroupFour";
import WhyChooseTwo from "../components/WhyChooseTwo";
import FooterOne from "../components/FooterOne";
import ServiceAreaOne from "../components/ServiceAreaOne";

const HomeFour = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavbarThree />

      {/* Banner Four */}
      <BannerFour />
      <CounterAreaFour />
      {/* About Area Four */}
      <AboutAreaFour />
      
      <ServiceAreaGroupFour/>
      {/* Service Area four */}
      {/* <ServiceAreaOne/> */}
      <ServiceAreaFour />
      <CareerAreaOne />
      <WhyChooseTwo/>

      {/* FAQ Area Two */}
      <FaqAreaTwo />

      {/* Team Area Two */}
      {/* <TeamAreaTwo /> */}

      {/* Career Area One */}

      {/* Project Area One */}
      {/* <ProjectAreaOne /> */}

      {/* Contact Area Four */}
      <ContactAreaFour />

      {/* Testimonial Three */}
      {/* <TestimonialThree /> */}

      {/* Counter Area Four */}
      

      {/* Blog Area Four */}
      {/* <BlogAreaFour /> */}

      {/* Brand Area One */}
      {/* <BrandAreaOne /> */}

      {/* Footer Three */}
      <FooterOne/>
    </>
  );
};

export default HomeFour;
