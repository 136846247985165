import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import axios from "axios";

const ContactAreaFour = () => {
  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    email: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state
  const [success, setSuccess] = useState(false); // Success state

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await axios.post("/contact-us/index.php", formData); // Ensure the backend URL is correct
      setIsLoading(false);
      setSuccess(true);
      setFormData({
        name: "",
        contact: "",
        email: "",
        message: "",
      });
    } catch (error) {
      setIsLoading(false);
      setError("Error sending message. Please try again later.");
      console.error(error);
    }
  };

  return (
    <div className="contact-area pd-top-90 pd-bottom-120">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-6 order-lg-end"
            data-aos="fade-left"
            data-aos-delay="200"
            data-aos-duration="1500"
          >
            <div className="about-thumb-inner ms-xl-5 p-xl-5 pt-4 ">
              <img
                className="animate-main-img"
                src="assets/img/about/18.png"
                alt="img"
              />
              <img
                className="main-img m-4"
                src="assets/img/about/contactus.jpg"
                alt="img"
              />
            </div>
          </div>
          <div
            className="col-lg-6 order-lg-first"
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-duration="1500"
          >
            <div className="section-title mb-0 mt-5 mt-lg-0">
              <h6 className="sub-title">GET IN TOUCH</h6>
              <h2 className="title">
                Your Business To <span>Growth</span> Please Touch?
              </h2>
              {/* <p className="content">
                For your car we will do everything advice design in us repairs
                and maintenance. We are the some preferred.
              </p> */}

              {/* Success Message */}
              {success && (
                <div className="alert alert-success" role="alert">
                  Message sent successfully!
                </div>
              )}

              {/* Error Message */}
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}

              <form className="mt-4" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="single-input-inner style-border">
                      <input
                        type="text"
                        name="name"
                        placeholder="Name Here"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="single-input-inner style-border">
                      <input
                        type="text"
                        name="contact"
                        placeholder="Contact Here"
                        value={formData.contact}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-input-inner style-border">
                      <input
                        type="email"
                        name="email"
                        placeholder="Email Here"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-input-inner style-border">
                      <textarea
                        name="message"
                        placeholder="Message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <button
                      type="submit"
                      className="btn btn-border-gray mt-0"
                      disabled={isLoading}
                    >
                      {isLoading ? "Sending..." : "Contact Us"} <FaPlus />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactAreaFour;
