import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const ServiceAreaFour = () => {
  return (
    <>
      {/*=================== service area start ===================*/}
      <div
        className='service-area bg-cover pd-top-120 pd-bottom-90 pd-top-110 pd-bottom-90'
        style={{ backgroundImage: 'url("./assets/img/bg/3.png")' }}
      >
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-xl-6 col-lg-8'>
              <div className='section-title text-center'>
                <h6 className='sub-title'>WHAT WE OFFER</h6>
                <h2 className='title'>
                  It <span>Service</span> Refers To The Provision Of Support
                </h2>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-xl-4 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-4'>
                  <img src='assets/img/icon/ui_new.svg' alt='img' />
                </div>
                <div className='details'>
                  <h5 className='mb-3'>UI/UX Design Services</h5>
                  <p className='content mb-3'>
                  We create intuitive and visually appealing user interfaces that enhance user experience and engagement. Our UI/UX design services focus on making your product a brand in the market.
</p>
                  <Link className='read-more-text' to='/ui-ux-service-details'>
                    Read More <FaArrowRight />
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-xl-4 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/web_new.svg' alt='img' />
                </div>
                <div className='details'>
                  <h5 className='mb-3'>Web Design & Development Services</h5>
                  <p className='content mb-3'>
                  We specialize in designing and developing responsive, functional, and engaging E-commerce websites. Our web design and development services help to meet your specific needs, delivering a strong online presence. </p>
                  <Link className='read-more-text' to='/web-design-service-details'>
                    Read More <FaArrowRight />
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-xl-4 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/mobile1.svg' alt='img' />
                </div>
                <div className='details'>
                  <h5 className='mb-3'>Mobile App Development (Android & iOS)</h5>
                  <p className='content mb-3'>
                
Our mobile app development services, delivering feature-rich, user-friendly apps tailored to your business needs. We ensure high performance and a smooth, engaging user experience.</p>
                  <Link className='read-more-text' to='/mobile-app-service-details'>
                    Read More <FaArrowRight />
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-xl-4 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/digital.svg' alt='img' />
                </div>
                <div className='details'>
                  <h5 className='mb-3'>Digital Marketing</h5>
                  <p className='content mb-3'>
                  We also offer digital marketing services to boost your online visibility and drive traffic. From content creation and PPC campaigns to social media management, it increase brand awareness, and generate leads </p>
                  <Link className='read-more-text' to='/digital-marketing-service-details'>
                    Read More <FaArrowRight />
                  </Link>
                </div>
              </div>
            </div>

            <div className='col-xl-4 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/seo.svg' alt='img' />
                </div>
                <div className='details'>
                  <h5 className='mb-3'>SEO (Search Engine Optimization)</h5>
                  <p className='content mb-3'>
                  Our SEO services help to improve your website’s ranking on search engines, driving organic traffic, and increasing online visibility. We implement the latest SEO techniques to optimize your site’s content and structure.
 </p>
                  <Link className='read-more-text' to='/seo-service-details'>
                    Read More <FaArrowRight />
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-xl-4 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/email.svg' alt='img' />
                </div>
                <div className='details'>
                  <h5 className='mb-3'>Email Marketing</h5>
                  <p className='content mb-3'>
                  Our email marketing services are designed to help you reach your customers directly with personalized messages. We create effective and interactive email campaigns that engage with your audience, and drive conversions. </p>
                  <Link className='read-more-text' to='/email-marketing-service-details'>
                    Read More <FaArrowRight />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </>
  );
};

export default ServiceAreaFour;
